import React, { FC, Suspense } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { useLoadInitialAppSettings } from '@hooks/app/initialSettings/useLoadInitialAppSettings'
import { useVivibilityChange } from '@hooks/app/useVisibilityChange'
import { useSetTitle } from '@hooks/app/useSetTitle'
import { AppActions } from '@components/app.actions'

import { lazyImport } from '@utils/lazyImport'

import { Initial } from '@components/Initial'
import { FullScreenLoader } from '@components/Overlays/FullScreenLoader/FullScreenLoader'
import { Overlays } from '@components/Overlays/Overlays'
import { Dialogs } from '@components/Dialogs/Dialogs'
import { LazyConfirmExitMovies } from '@components/Dialogs/ConfirmExitMovies/LazyConfirmExitMovies'
import { LazyCheckPassword } from '@components/Overlays/ParentalControl/CheckPassword/LazyCheckPassword'
import { LazySetPassword } from '@components/Overlays/ParentalControl/SetPassword/LazySetPassword'

const { Player } = lazyImport(
  () => import(/*webpackChunkName: 'player'*/ '@components/Player/Player'),
  'Player'
)
const { Movies } = lazyImport(
  () => import(/*webpackChunkName: 'movies'*/ '@components/Movies/Movies'),
  'Movies'
)
const { PageNotFound } = lazyImport(
  () =>
    import(
      /*webpackChunkName: 'page-not-found'*/ '@components/PageNotFound/PageNotFound'
    ),
  'PageNotFound'
)

const App: FC = () => {
  const settingsIsLoaded = useLoadInitialAppSettings()

  useSetTitle(settingsIsLoaded)

  // Fix for Tizen OS
  useVivibilityChange()

  if (!settingsIsLoaded) return null

  return (
    <>
      <AppActions />
      <Initial>
        <Router>
          <Suspense fallback={<FullScreenLoader loaderSize={3} />}>
            <Player />
          </Suspense>
          <Switch>
            <Route path={`${__BASEPATH__}`} exact />
            <Route path={`${__BASEPATH__}/movies/category/:category_id`}>
              <Suspense fallback={<FullScreenLoader loaderSize={3} />}>
                <Movies />
              </Suspense>
            </Route>
            <Route>
              <Suspense fallback={null}>
                <PageNotFound />
              </Suspense>
            </Route>
          </Switch>
          <LazyConfirmExitMovies />
          <LazyCheckPassword />
          <LazySetPassword />
        </Router>
      </Initial>
      {/* Dialogs */}
      <Dialogs />
      {/* Overlays */}
      <Overlays />
    </>
  )
}

export default App
